"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));

var _lodash = _interopRequireDefault(require("lodash"));

var _default = {
  props: ['network'],
  computed: {
    ignoredUsers: function ignoredUsers() {
      return (0, _filter.default)(_lodash.default).call(_lodash.default, this.network.users, function (u) {
        return u.ignore;
      });
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["IgnoreList"]
window._kiwi_exports.components.IgnoreList = exports.default ? exports.default : exports;
